/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const NewsComponent = ({ data, showNewsDetails }) => {
	const openDetails = () => {
		showNewsDetails(data);
	};

	return (
		<div className="list_inner" style={{ height: "465px", backgroundColor: "#000" }}>
			{/* //* ********** START IMAGE */}
			<div className="image" onClick={openDetails}>
				<img src="assets/img/thumbs/4-3.jpg" alt="thumb" />
				<div
					className="main"
					style={{
						backgroundImage: `url('${data.find((item) => item.type === "image").component}')`,
						filter: `grayscale(100%)`,
					}}
				></div>
			</div>
			{/* //* ********** END IMAGE */}
			<div className="details">
				{/* <div className="extra">
					<p className="date">
						By <a href="#">Alex Watson</a>
						<span>05 April 2021</span>
					</p>
				</div> */}
				{/* END EXTRA */}

				<h3 className="title" onClick={openDetails}>
					{data.find((item) => item.comp_order == 0).component}
				</h3>
				<div className="tokyo_tm_read_more">
					<a onClick={openDetails}>
						<span>Read More</span>
					</a>
				</div>
				{/* END READ MORE BUTTON */}
			</div>
			{/* END DETAILS */}
		</div>
	);
};

export default NewsComponent;
