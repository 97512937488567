import React from "react";

import classes from "./Spinner.module.css";

const Spinner = () => {
	return (
		<div className={classes.spinnerContainer}>
			<div className={classes.lds_dual_ring}></div>
		</div>
	);
};

export default Spinner;
