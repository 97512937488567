import React, { useEffect } from "react";
import Social from "./Social";

const Home = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	});

	return (
		<>
			<div className="tokyo_tm_home" style={{ flexDirection: "column" }}>
				<div className="home_content " style={{ marginBottom: "2rem" }}>
					<div className="avatar">
						<div className="image avatar_img"></div>
					</div>
					<div className="details">
						<h3 className="name">Ibrahim Azar</h3>
						<p>
							Highly experienced communications and marketing professional with over 15 years of experience in developing and delivering
							strong strategic communications and marketing programs, Ibrahim has worked directly with leadership teams, large
							organizations (Governmental & Non-Governmental), managing expectations and securing support, while mapping out
							communication and engagement strategies aligned to commercial objectives.
						</p>
					</div>
				</div>
				<div>
					<p>
						By leveraging his extensive experience in Digital, PR, Communications, Media Relations, Crisis Management, as well as managing
						and motivating teams working across the MENA region, Ibrahim ensured that these corporations were able to transform well, from
						successfully riding the wave of the digital era to creating compelling narratives on a global stage.
					</p>
					<br />
					<p>
						Additionally, Ibrahim provides exclusive executive workshops at top regional universities and provides training programs to
						different private, governmental and semi-governmental organizations across the MENA region.
					</p>
					<p>
						Ibrahim holds two Master degrees (MBA & Masters of Science In International business Strategies) as well various
						certifications from digital and financial fields.
					</p>
				</div>
				<span style={{ marginTop: "1rem", alignSelf: "flex-start" }}>
					<Social />
				</span>
			</div>
			{/* END HOME */}
		</>
	);
};

export default Home;
