import React, { useState, useRef } from "react";
// import ReactMapGL from "react-map-gl";

import axios from "../utils/axios";

const Contact = () => {
	// const [viewport, setViewport] = useState({
	// 	latitude: 40.712776,
	// 	longitude: -74.005974,
	// 	zoom: 8,
	// });

	const defaultErrors = { nameError: null, emailError: null, messageError: null };
	const [errors, setErrors] = useState(defaultErrors);
	const [formState, setFormState] = useState({ message: null, type: null });

	const nameRef = useRef(null);
	const emailRef = useRef(null);
	const messageRef = useRef(null);

	function sendEmail(e) {
		e.preventDefault();

		const name_value = nameRef.current.value;
		const email_value = emailRef.current.value;
		const message_value = messageRef.current.value;

		if (!name_value || !email_value || !message_value) {
			setErrors({
				nameError: name_value ? null : "Name could not be empty",
				emailError: email_value ? null : "E-mail could not be empty",
				messageError: message_value ? null : "Message could not be empty",
			});

			return;
		}

		setErrors(() => defaultErrors);

		const posted_data = {
			name: nameRef.current.value,
			email: emailRef.current.value,
			message: messageRef.current.value,
		};

		axios({
			method: "POST",
			url: "/contact_us",
			data: posted_data,
		})
			.then((res) => {
				setErrors(() => defaultErrors);
				setFormState({ message: "Form sent successfully", type: "success" });
				e.target.reset();
			})
			.catch((err) => {
				console.error(err);
				setFormState({ message: `An error has occured please try again!${err}`, type: "error" });
			});
	}

	return (
		<>
			<div className="tokyo_tm_contact">
				<div className="tokyo_tm_title" style={{ marginBottom: "1rem" }}>
					<div className="title_flex">
						<div className="left">
							<span>Contact</span>
							<h3>Get in Touch</h3>
						</div>
					</div>
				</div>
				{/* END TITLE */}

				{/* <div className="map_wrap">
					<div className="map">
						<ReactMapGL
							mapStyle={"mapbox://style/mapbox/dark-v9"}
							mapboxApiAccessToken="pk.eyJ1IjoiYmF5YXppZGgiLCJhIjoiY2tvemdwc3ByMDg1YzJubzQxcDR0cDR3dyJ9.s1zXEb5OPqgBDcmupj3GBA"
							{...viewport}
							onViewportChange={(nextViewport) => setViewport(nextViewport)}
						/>
					</div>
				</div> */}
				{/* MENU WRAP */}

				<div className="fields">
					<form className="contact_form" onSubmit={sendEmail}>
						<div className="first">
							<ul>
								<li>
									<input ref={nameRef} type="text" name="name" placeholder="Name" />
									{errors.nameError && <span>{errors.nameError}</span>}
								</li>
								{/* END FIRST NAME */}

								<li>
									<input ref={emailRef} type="email" name="user_email" placeholder="Email" />
									{errors.emailError && <span>{errors.emailError}</span>}
								</li>
								{/* END EMAIL */}

								<li style={{ marginBottom: "1rem" }}>
									<textarea ref={messageRef} name="message" placeholder="Message"></textarea>
									{errors.messageError && <span>{errors.messageError}</span>}
								</li>
								{/* END SUBJECT MESSAGE */}
							</ul>
						</div>
						<div style={{ marginBottom: "1rem" }}>
							{formState.message && <span style={{ color: formState.type === "error" ? "red" : "green" }}>{formState.message}</span>}
						</div>

						<div className="tokyo_tm_button">
							<button type="submit" className="ib-button">
								Send Message
							</button>
						</div>
						{/* END SUBMIT BUTTON */}
					</form>
					{/* END FORM */}
				</div>
				{/* END FIELDS */}
			</div>
			{/* END COTACT */}
		</>
	);
};

export default Contact;
