import React, { useEffect, useState } from "react";
import axios from "../utils/axios";

// import Social from "./Social";
import Spinner from "./Spinner.jsx";
import NewsComponent from "./NewsComponent";

const News = (props) => {
	const [newsData, setNewsData] = useState([]);
	// const [newsDetails, setNewsDetails] = useState({
	// 	visible: false,
	// 	data: [],
	// });
	const showNewsDetails = (data) => {
		if (Array.isArray(data) && data.length > 0) {
			props.setNewsDetails(() => ({
				visible: true,
				data,
			}));
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	};
	const hideNewsDetails = () => {
		props.setNewsDetails(() => ({
			visible: false,
			data: [],
		}));
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		axios({
			method: "POST",
			url: "get_news",
		})
			.then((res) => {
				setNewsData(() => res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			{props.newsDetails && props.newsDetails.visible ? (
				// * **********************************************************
				// * ******************** NEWS DETAILS ************************
				// * **********************************************************
				<div id="news-details-container" className="news-details-container">
					<div className="close-news-details-container">
						<button className="close-news-details-btn" onClick={hideNewsDetails}>
							<img src="assets/img/svg/cancel.svg" alt="close icon" />
						</button>
					</div>
					<div className="box_inner">
						<div className="description_wrap scrollable">
							<div className="main_content">
								<div className="descriptions">
									{props.newsDetails.data.map((item, index) => {
										switch (item.type) {
											// * ************************
											case "image":
												return (
													<div className="image" key={item.component_id}>
														<img src={item.component} alt="thumb" />
													</div>
												);
											// * ************************
											case "title":
												return (
													<div className="details" key={item.component_id}>
														<h3 className="title">{item.component}</h3>
													</div>
												);
											// * ************************
											case "text":
												return (
													<p className="news-details-text" key={item.component_id}>
														{item.component}
													</p>
												);
											// * ************************
											case "quote":
												return (
													<div className="quotebox" key={item.component_id}>
														<div className="icon">
															<img className="svg" src="assets/img/svg/quote.svg" alt="tumb" />
														</div>
														<p>{item.component}</p>
													</div>
												);
											// * ************************
											default:
												return <></>;
										}
									})}
									{/* <div className="news_share">
										<span>Share:</span>
										<Social />
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				// * **********************************************************
				// * ************************ ALL NEWS ************************
				// * **********************************************************
				<div className="tokyo_tm_news">
					{/* //* *************** START NEWS TITLE */}
					<div className="tokyo_tm_title">
						<div className="title_flex">
							<div className="left">
								<span>News</span>
								<h3>Latest News</h3>
							</div>
						</div>
					</div>
					{/* //* *************** END NEWS TITLE */}

					{/* //* *************** START NEWS LIST */}

					{newsData.length === 0 ? (
						<Spinner />
					) : (
						<ul>
							{Object.keys(newsData).map((item, index) => (
								<li key={index} data-aos="fade-right" data-aos-duration="1200" data-aos-delay={index * 200}>
									<NewsComponent data={newsData[item] || []} showNewsDetails={showNewsDetails} />
								</li>
							))}
						</ul>
					)}

					{/* //* *************** END NEWS LIST */}
				</div>
			)}
		</>
	);
};

export default News;
