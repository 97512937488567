import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { Link } from "react-router-dom";
import Home from "../../components/Home";
import About from "../../components/About";
// import Portfolio from "../../components/Portfolio";
import News from "../../components/News";
import Contact from "../../components/Contact";
import Producer from "../../components/Producer";

const HomeDark = () => {
	document.body.classList.add("dark");

	const [newsDetails, setNewsDetails] = useState({
		visible: false,
		data: [],
	});

	return (
		<>
			<Tabs>
				<TabList>
					{/* START LEFT MENU CONTENT */}
					<div className="leftpart">
						<div className="leftpart_inner">
							<div className="logo">
								{/* <Link className="navbar-brand" to="/"> */}
								{/* <img src="/assets/img/logo/dark.png" alt="brand" /> */}
								<h5 className="tokyo_tm_title">IBRAHIM AZAR</h5>
								{/* </Link> */}
							</div>
							{/* END LOGO */}

							<div className="menu">
								<ul>
									<Tab>
										<img className="svg" src="/assets/img/svg/home-run.svg" alt="homerun" />
										<span className="menu_content">Home</span>
									</Tab>
									<Tab>
										<img className="svg" src="/assets/img/svg/avatar.svg" alt="avatar" />
										<span className="menu_content">About</span>
									</Tab>
									<li className="react-tabs__tab">
										<a href="https://producer.ibraheemazar.com/">
											<img
												className="svg"
												src="/assets/img/director_producer_icon.svg"
												alt="producer"
												style={{ filter: "invert(1)" }}
											/>
											<span className="menu_content" style={{ position: "relative", left: "-4px" }}>
												{" "}
												Producer
											</span>
										</a>
									</li>
									{/* <Tab>
										<img
											className="svg"
											src="/assets/img/svg/briefcase.svg"
											alt="briefcase"
										/>
										<span className="menu_content">Portfolio</span>
									</Tab> */}
									<Tab style={{ position: "relative" }}>
										<img className="svg" src="/assets/img/svg/paper.svg" alt="paper" />
										<div
											style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}
											onClick={() => {
												setNewsDetails(() => ({
													visible: false,
													data: [],
												}));
											}}
										></div>
										<span className="menu_content">News</span>
									</Tab>
									<Tab>
										<img className="svg" src="/assets/img/svg/mail.svg" alt="mail" />
										<span className="menu_content"> Contact</span>
									</Tab>
								</ul>
							</div>
							{/* END MENU */}

							{/* <div className="copyright">
								<p>
									&copy; All Rights reserved to
									<a href="https://brainspace.sa/" target="_blank" rel="noreferrer">
										Brainspace
									</a>
								</p>
							</div> */}
							{/* END COPYRIGHT */}
						</div>
					</div>
					{/* END LEFT MENU CONTENT */}
				</TabList>
				{/* END SIDEBAR TABLIST */}

				{/* START RIGHT PART CONTENT */}
				<div className="rightpart">
					<div className="rightpart_in">
						<div className="tokyo_tm_section">
							<TabPanel>
								<div className="" style={{ margin: "0 5%" }}>
									<div data-aos="fade-right" data-aos-duration="1200">
										<Home />
									</div>
								</div>
							</TabPanel>
							{/* END HOME MENU TAB CONTENT */}

							<TabPanel>
								<div className="container">
									<div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100">
										<About />
									</div>
								</div>
							</TabPanel>
							{/* <TabPanel>
								<div className="producer-container container" style={{ padding: 0 }}>
									<div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
										<Producer />
									</div>
								</div>
							</TabPanel> */}
							{/* END ABOUT MENU TAB CONTENT */}

							{/* <TabPanel>
									<Portfolio />
								</TabPanel> */}
							{/* END PORTFOLIO MENU TAB CONTENT */}

							<TabPanel>
								<div className="container">
									<News setNewsDetails={(data) => setNewsDetails(data)} newsDetails={newsDetails} />
								</div>
							</TabPanel>
							{/* END NEWS MENU TAB CONTENT */}

							<TabPanel>
								<div className="container">
									<div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
										<Contact />
									</div>
								</div>
							</TabPanel>
							{/* END CONTACT MENU TAB CONTENT */}
						</div>
					</div>
				</div>
				{/* END RIGHT PART CONTENT */}
			</Tabs>
			{/* END TABS */}
		</>
	);
};

export default HomeDark;
