import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Brand from "./Brand";

Modal.setAppElement("#root");

const About = () => {
	const [isOpen, setIsOpen] = useState(false);

	function toggleModal() {
		setIsOpen(!isOpen);
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<>
			<div className="tokyo_tm_about">
				<div className="about_image ">
					<img src="/assets/img/ibraheem-azar-profile.jpeg" alt="about" />
				</div>
				{/* <div className="o-video">
          <iframe src="https://www.youtube.com/embed/0yW7w8F2TVA"></iframe>
        </div> */}
				{/* END ABOUT IMAGE */}
				<div className="description">
					<h3 className="name">About Ibrahim Azar</h3>
					<div className="description_inner">
						<div className="left" style={{ marginBottom: "0" }}>
							<p style={{ marginBottom: 0 }}>
								Highly experienced communications and marketing professional with over 15 years of experience in developing and
								delivering strong strategic communications and marketing programs.
							</p>
							{/* <div className="tokyo_tm_button">
								<button onClick={toggleModal} className="ib-button">
									Read More
								</button>
							</div> */}
						</div>
						<div className="right hide_mobile">
							<ul>
								{/* <li>
									<p>
										<span>Birthday:</span>28/1/2022
									</p>
								</li>
								<li>
									<p>
										<span>Age:</span>31
									</p>
								</li> */}
								{/* <li>
									<p>
										<span>Address:</span>Ave 11, New York, USA
									</p>
								</li> */}
								<li>
									<p>
										<span>Email:</span>
										<a href="mailto:Ibraheem.azar@gmail.com">Ibraheem.azar@gmail.com</a>
									</p>
								</li>
								<li>
									<p>
										<span>Phone:</span>
										<a href="tel:+966 53 523 5321">+966 53 523 5321</a>
									</p>
								</li>
								{/* <li>
									<p>
										<span>Study:</span>Univercity of Texas
									</p>
								</li> */}
							</ul>
							{/* END UL */}
						</div>
						{/* END RIGHT */}
					</div>
					<br />
					<div>
						<p>
							Ibrahim has worked directly with leadership teams, large organizations (Governmental & Non-Governmental), managing
							expectations and securing support, while mapping out communication and engagement strategies aligned to commercial
							objectives.
						</p>
						<br />
						<p>
							By leveraging his extensive experience in Digital, PR, Communications, Media Relations, Crisis Management, as well as
							managing and motivating teams working across the MENA region, Ibrahim ensured that these corporations were able to
							transform well, from successfully riding the wave of the digital era to creating compelling narratives on a global stage.
						</p>
						<br />
						<p>
							Additionally, Ibrahim provides exclusive executive workshops at top regional universities and provides training programs
							to different private, governmental and semi-governmental organizations across the MENA region.
						</p>
						<p>
							Ibrahim holds two Master degrees (MBA & Masters of Science In International business Strategies) as well various
							certifications from digital and financial fields.
						</p>
					</div>

					<div className="tokyo_tm_modalbox_about">
						<div className="box-inner" style={{ color: "white", width: "100%", height: "100%", background: "transparent" }}>
							<div
								className="description_wrap"
								data-aos="fade-right"
								data-aos-duration="1200"
								style={{ padding: 0, paddingTop: "3rem" }}
							>
								<div className="my_box">
									<div className="left">
										<div className="about_title">
											<h3>SKILLS</h3>
										</div>
										{/* END ABOUT TITLE */}

										<div className="tokyo_progress">
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Global Public Relations </span>
													{/* <span className="number">95%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>

											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Brand Transformations </span>
													{/* <span className="number">80%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>

											<div className="progress_inner" data-value="100">
												<span>
													<span className="label"> Executive Coaching </span>
													{/* <span className="number">90%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>

											<div className="progress_inner" data-value="100">
												<span>
													<span className="label"> Change Management</span>
													{/* <span className="number">90%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Crisis Management </span>
													{/* <span className="number">90%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Social & Digital Media </span>
													{/* <span className="number">90%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Partnership & Alliance Formation </span>
													{/* <span className="number">90%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Press Releases</span>
													{/* <span className="number">90%</span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="right">
										<div className="about_title">
											<h3>EDUCATIONS & CERTIFICATIONS </h3>
										</div>
										{/* END TITLE */}
										<div className="tokyo_progress">
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">MBA (Notre Dame University)</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Google Partner</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Facebook Blueprint Certified</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">Inbound Marketing Certified</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>

											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">
														Masters of Science In International business Strategies (Kedge Business School)
													</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">
														Certificate in Securities from The Chartered Institute for Securities & Investment (The CISI)
													</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>

											<div className="progress_inner" data-value="100">
												<span>
													<span className="label">
														Certificate in Financial Regulations from a governmental financial institution
													</span>
													{/* <span className="number"></span> */}
												</span>
												<div className="background">
													<div className="bar">
														<div className="bar_in" style={{ width: 100 + "%" }}></div>
													</div>
												</div>
											</div>
										</div>
										{/* EDN TOKYO PROGRESS */}
									</div>
									{/* END RIGHT */}
								</div>
								{/* END MYBOX */}

								<div className="counter">
									<div className="about_title">
										<h3>KEY AREAS OF EXPERIENCE </h3>
									</div>
									<ul>
										<li>
											<div className="list_inner" style={{ height: "140px" }}>
												<h3>15+</h3>
												<span>years media experience</span>
											</div>
										</li>
										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span> Digital Transformation</span>
											</div>
										</li>
										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span> Executing Long and Short-Term Communications Plans </span>
											</div>
										</li>

										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span>
													Media relations expert, effective communicator, exceptional leadership and management abilities
												</span>
											</div>
										</li>
										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span>Consulting on Change Management </span>
											</div>
										</li>
										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span>Cultivating Data-Centric Environments </span>
											</div>
										</li>
										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span>Defining Key Crisis Management Tactics</span>
											</div>
										</li>

										<li>
											<div
												className="list_inner"
												style={{
													height: "140px",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<span>Diverse project execution background thought-leadership campaigns and event planning</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="right show_mobile">
						<ul>
							<li>
								<p>
									<span>Birthday:</span>28/1/2022
								</p>
							</li>
							<li>
								<p>
									<span>Age:</span>31
								</p>
							</li>
							{/* <li>
									<p>
										<span>Address:</span>Ave 11, New York, USA
									</p>
								</li> */}
							<li>
								<p>
									<span>Email:</span>
									<a href="mailto:mail@gmail.com">mail@gmail.com</a>
								</p>
							</li>
							<li>
								<p>
									<span>Phone:</span>
									<a href="tel:+770221770505">+77 022 177 05 05</a>
								</p>
							</li>
							{/* <li>
									<p>
										<span>Study:</span>Univercity of Texas
									</p>
								</li> */}
						</ul>
						{/* END UL */}
					</div>
				</div>
			</div>

			{/* START ABOUT POPUP BOX */}
			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}
			>
				<div className="tokyo_tm_modalbox_about">
					<button className="close-modal" onClick={toggleModal}>
						<img src="assets/img/svg/cancel.svg" alt="close icon" />
					</button>
					{/* END POPUP CLOSE BUTTON */}
					<div className="box-inner" style={{ width: "100%", height: "100%" }}>
						<div className="description_wrap scrollable" data-aos="fade-right" data-aos-duration="1200">
							<div className="my_box">
								<div className="left">
									<div className="about_title">
										<h3>Photography Skills</h3>
									</div>
									{/* END ABOUT TITLE */}

									<div className="tokyo_progress">
										<div className="progress_inner" data-value="95">
											<span>
												<span className="label">Wedding Photography</span>
												<span className="number">95%</span>
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 95 + "%" }}></div>
												</div>
											</div>
										</div>

										<div className="progress_inner" data-value="80">
											<span>
												<span className="label">Lifestyle Photography</span>
												<span className="number">80%</span>
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 80 + "%" }}></div>
												</div>
											</div>
										</div>

										<div className="progress_inner" data-value="90">
											<span>
												<span className="label">Family Photography</span>
												<span className="number">90%</span>
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 90 + "%" }}></div>
												</div>
											</div>
										</div>
									</div>
									{/* END PROGRESS */}
								</div>
								{/* END LEFT */}

								<div className="right">
									<div className="about_title">
										<h3>EDUCATIONS & CERTIFICATIONS </h3>
									</div>
									{/* END TITLE */}
									<div className="tokyo_progress">
										<div className="progress_inner" data-value="100">
											<span>
												<span className="label">MBA (Notre Dame University)</span>
												{/* <span className="number"></span> */}
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 100 + "%" }}></div>
												</div>
											</div>
										</div>
										<div className="progress_inner" data-value="100">
											<span>
												<span className="label">Google Partner</span>
												{/* <span className="number"></span> */}
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 100 + "%" }}></div>
												</div>
											</div>
										</div>
										<div className="progress_inner" data-value="100">
											<span>
												<span className="label">Facebook Blueprint Certified</span>
												{/* <span className="number"></span> */}
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 100 + "%" }}></div>
												</div>
											</div>
										</div>
										<div className="progress_inner" data-value="100">
											<span>
												<span className="label">Inbound Marketing Certified</span>
												{/* <span className="number"></span> */}
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 100 + "%" }}></div>
												</div>
											</div>
										</div>
										<div className="progress_inner" data-value="100">
											<span>
												<span className="label">
													Certificate in Securities from The Chartered Institute for Securities & Investment (The CISI)
												</span>
												{/* <span className="number"></span> */}
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 100 + "%" }}></div>
												</div>
											</div>
										</div>

										<div className="progress_inner" data-value="100">
											<span>
												<span className="label">
													Certificate in Financial Regulations from a governmental financial institution
												</span>
												{/* <span className="number"></span> */}
											</span>
											<div className="background">
												<div className="bar">
													<div className="bar_in" style={{ width: 100 + "%" }}></div>
												</div>
											</div>
										</div>
									</div>
									{/* EDN TOKYO PROGRESS */}
								</div>
								{/* END RIGHT */}
							</div>
							{/* END MYBOX */}

							<div className="counter">
								<div className="about_title">
									<h3>Fun Facts</h3>
								</div>
								<ul>
									<li>
										<div className="list_inner">
											<h3>777+</h3>
											<span>Projects Completed</span>
										</div>
									</li>
									<li>
										<div className="list_inner">
											<h3>3K</h3>
											<span>Happy Clients</span>
										</div>
									</li>
									<li>
										<div className="list_inner">
											<h3>9K+</h3>
											<span>Lines of Code</span>
										</div>
									</li>
								</ul>
								{/* END COUNTER CONTENT */}
							</div>
							{/* END COUNTER */}

							<div className="partners">
								<div className="about_title">
									<h3>Our Partners</h3>
								</div>
								<Brand />
							</div>
							{/* END PARTNER SLIDER */}
						</div>
					</div>
				</div>
			</Modal>
			{/* END ABOUT POPUP BOX */}
		</>
	);
};

export default About;
